import { useState } from "react";
import React from "react";
import TeleAppPage from "./TeleAppPage/index";
import LoadingGame from "./TeleAppPage/components/loadingGame";
import styles from "./RollItemPage.module.scss";
import logoapp from "../../assets/images/teleImage/iconSlime.png";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { FaBook } from "react-icons/fa";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { useWriteContracts } from "wagmi/experimental";
import { CHECKIN_CONTRACT_ADDRESS_BASE, abi } from "../../ABI/checkinDaily";
import { parseEther } from "viem";
import { useReadContract } from "wagmi";
import { STAKE_CONTRACT_ADDRESS_BASE, abiStake } from "../../ABI/StakeToken";
export default function Spin() {
	const accountWagmi = useAccount();
	const [walletType, setWalletType] = useState();
	const { writeContracts, writeContractsAsync } = useWriteContracts();
	const publicClient = usePublicClient();
	const { writeContractAsync } = useWriteContract();
	const { data: bananceOfAddress, refetch: refreshData } = useReadContract({
		abi,
		functionName: "balanceOf",
		address: CHECKIN_CONTRACT_ADDRESS_BASE,
		args: [accountWagmi.address!],
	});
	console.log({ bananceOfAddress });
	const dailyCheckIn = async () => {
		console.log(accountWagmi?.address);
		if (accountWagmi?.address === undefined) {
			// const session = await stavaxAccount.connect();
			// console.log({ session });
			// const botURL = stavaxAccount.getTgBotWebAppURL(session!).value;
			// console.log({ botURL });
		} else {
			try {
				const hash = await writeContractAsync({
					abi,
					address: CHECKIN_CONTRACT_ADDRESS_BASE,
					functionName: "mint",
					value: parseEther("0"),
				});

				const transaction =
					await publicClient?.waitForTransactionReceipt({
						hash: hash as any,
						confirmations: 3,
					});
				console.log({ hash });
				refreshData();
			} catch (e: any) {
				console.log({ e });
			}
		}
	};

	const stakeToken = async () => {
		try {
			const hash = await writeContractAsync({
				abi,
				address: CHECKIN_CONTRACT_ADDRESS_BASE,
				functionName: "approve",
				args: [accountWagmi.address!, BigInt(6)],
			});
			publicClient?.waitForTransactionReceipt({
				hash: hash as any,
			});
			const hashStake = await writeContractAsync({
				abi: abiStake,
				address: STAKE_CONTRACT_ADDRESS_BASE,
				functionName: "stake",
				args: [parseEther("1")],
			});
			publicClient?.waitForTransactionReceipt({
				hash: hashStake as any,
			});
		} catch (e: any) {
			console.log({ e });
		}
	};
	const DefaultComponent = () => {
		return (
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						columnGap: "10px",
						fontFamily: "Poppins",
						justifyContent: "center",
					}}
				>
					<img src={logoapp} alt="Logo" className={styles.logo} />
					<h1 className={styles.title}>Slime Revolution</h1>
				</div>
				<p className={styles.subtitle}>
					{`Slime Revolution is a blockchain-based social game that merges gaming excitement with cryptocurrency rewards`}
				</p>
			</>
		);
	};

	return (
		<div className={styles.main1}>
			{accountWagmi?.address === undefined ? (
				<LoadingGame
					walletType={(value: React.SetStateAction<undefined>) => {
						console.log(value);
						setWalletType(value);
					}}
				/>
			) : (
				<div className={styles.main1}>
					<div className={styles.containerWallet}>
						<DefaultComponent />
						<button
							className={styles.connectMetamaskButton}
							onClick={dailyCheckIn}
							id="button"
						>
							Mint check-in token
						</button>
						<button
							className={styles.connectMetamaskButton}
							onClick={() => {
								stakeToken();
							}}
							id="button"
						>
							Stake check-in token
						</button>
						<div className={styles.aboutStyle}>
							<a
								href="https://t.me/SlimeRoyale"
								target="_blank"
								style={{
									color: "black",
									fontFamily: "Poppins",
									fontSize: "12px",
									display: "flex",
									alignItems: "center",
									columnGap: "4px",
								}}
								rel="noopener noreferrer"
							>
								<FaTelegramPlane size={16} color="black" />
								<span
									style={{
										paddingTop: "1px",
									}}
								>{` Join Telegram`}</span>
							</a>
							<a
								href="https://x.com/SlimeRoyale/"
								target="_blank"
								style={{
									color: "black",
									fontFamily: "Poppins",
									fontSize: "12px",
									display: "flex",
									alignItems: "center",
									columnGap: "4px",
								}}
								rel="noopener noreferrer"
							>
								<FaTwitter size={16} color="black" />
								<span
									style={{
										paddingTop: "1px",
									}}
								>{` Follow us on Twitter`}</span>
							</a>
							<a
								style={{
									color: "black",
									fontFamily: "Poppins",
									fontSize: "12px",
									display: "flex",
									alignItems: "center",
									columnGap: "4px",
								}}
								href="https://about.slimerevolution.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaBook size={16} color="black" />
								<span
									style={{
										paddingTop: "1px",
									}}
								>{` About Slime Revolution`}</span>
							</a>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
